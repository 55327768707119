import { useState } from 'react';

// eslint-disable-next-line import/no-named-as-default
import Big from 'big.js';

import { ReceiptLongOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  ContractEntry,
  PayrollElement,
  PayrollPayslipEntry,
  PayrollTypes,
  useGetCompanyEntry,
  useGetContract,
} from '@octopus/api';
import { getWorkerId, isBRCltContract } from '@octopus/contract-types';
import { isTrabalhadorAutonomo } from '@octopus/esocial/contracts';
import {
  capitalize,
  formatCNPJ,
  formatCPF,
  formatDateBR,
  formatMoney,
  formatPercentage,
  formatPeriodDate,
} from '@octopus/formatters';
import { payrollTypes } from '@octopus/payroll-types';

import { DataFetching } from '../../dataFetching';
import { parsePixKey } from '../../payrolls/parser';
import DoubleColumnListRow from '../DoubleColumnListRow';
import { StatusIndicator } from '../payslips/StatusIndicator';

import { CalculationExplanation } from './CalculationExplanation';
import { ElementsTable } from './ElementsTable';

export default function Payslip({
  payslip,
  actionMenu,
  receipt = false,
}: {
  payslip: PayrollPayslipEntry;
  actionMenu: () => JSX.Element;
  receipt?: boolean;
}) {
  return (
    <Box
      data-testid="payslips-details"
      display="flex"
      sx={(theme) => ({
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          justifyContent: 'space-between',
          pt: 4,
          pb: 10,
        },
        [theme.breakpoints.down('md')]: {
          pt: 2,
          pb: 2,
          px: 3,
        },
      })}
    >
      <PayslipWorkerInformation
        payslip={payslip}
        actionMenu={actionMenu}
        receipt={receipt}
      />

      <Divider sx={{ my: 3 }} />

      <ElementsTables payslip={payslip} />

      <PayslipSummary payslip={payslip} />

      <Divider sx={{ my: 2 }} />

      <CompanyFooter
        organizationId={payslip.organizationId}
        companyId={payslip.companyId}
      />

      {!receipt && <StatusIndicator payslip={payslip} />}
    </Box>
  );
}

function ExplanationContent({
  elementStack,
  onClickElement,
  onClose,
  onGoBack,
}: {
  elementStack: PayrollElement[];
  onClickElement: (element: PayrollElement) => void;
  onClose: () => void;
  onGoBack: () => void;
}) {
  return (
    <Box
      sx={{
        opacity: elementStack.length > 0 ? 1 : 0,
        transition: 'all 0.2s',
        backgroundColor: 'background.default',
        overflowY: 'overlay',
      }}
      data-testid="payslip-calculation-explanation"
    >
      <CalculationExplanation
        element={elementStack[elementStack.length - 1]}
        onClickElement={onClickElement}
        canGoBack={elementStack.length > 1}
        goBack={onGoBack}
        onExit={onClose}
      />
    </Box>
  );
}

function ElementsTables({ payslip }: { payslip: PayrollPayslipEntry }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [elementStack, setElementStack] = useState<PayrollElement[]>([]);

  const onClickElement = (element: PayrollElement) => {
    if (element.id === elementStack[elementStack.length - 1]?.id) {
      setElementStack([]);
      return;
    }
    setElementStack((state) => [
      ...state,
      payslip.outputs?.elements[element.id],
    ]);
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    setElementStack([]);
    setIsDrawerOpen(false);
  };

  const handleGoBack = () => {
    setElementStack((prevStack: PayrollElement[]) => prevStack.slice(0, -1));
  };

  return (
    <>
      <Box
        borderRadius={1.5}
        borderColor="strokes.light"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          mx: 0.5,
        }}
      >
        <Box px={2} pb={4}>
          <Typography
            variant="h3"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Proventos
          </Typography>
          <ElementsTable
            payroll={payslip}
            summary="workerEarningsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />
        </Box>
        <Box px={2} pb={4}>
          <Typography
            variant="h3"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Deduções
          </Typography>

          <ElementsTable
            payroll={payslip}
            summary="workerDeductionsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />
        </Box>

        <Box px={4} pt={3}>
          <Divider />
        </Box>

        <Box px={4} py={3} display="flex" justifyContent="space-between">
          <table
            width="100%"
            style={{
              tableLayout: 'fixed',
              borderCollapse: 'collapse',
            }}
          >
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Typography variant="h4" color="text.primary">
                    Valor líquido total
                  </Typography>
                </td>
                <td colSpan={1}>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    textAlign="left"
                  >
                    {formatMoney(payslip.outputs?.netPay?.total)}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>

      {/* Desktop Drawer */}
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
        anchor="right"
        open={isDrawerOpen}
        onClose={handleClose}
        elevation={2}
      >
        <Box width="520px" height="100%">
          <ExplanationContent
            elementStack={elementStack}
            onClickElement={onClickElement}
            onClose={handleClose}
            onGoBack={handleGoBack}
          />
        </Box>
      </Drawer>

      {/* Mobile Drawer */}
      <Drawer
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
        anchor="bottom"
        open={isDrawerOpen}
        onClose={handleClose}
        elevation={2}
        PaperProps={{
          sx: { borderRadius: '16px 16px 0 0' },
        }}
      >
        <Box height="75vh">
          <ExplanationContent
            elementStack={elementStack}
            onClickElement={onClickElement}
            onClose={handleClose}
            onGoBack={handleGoBack}
          />
        </Box>
      </Drawer>
    </>
  );
}

function PayslipWorkerInformation({
  payslip,
  actionMenu,
  receipt,
}: {
  payslip: PayrollPayslipEntry;
  actionMenu: () => JSX.Element;
  receipt?: boolean;
}) {
  const useFetch = () => {
    return useGetContract({
      pathParams: {
        organizationId: payslip.organizationId,
        contractId: payslip.contractId,
      },
    });
  };
  const rpaInfoFields = (data: ContractEntry, payslip: PayrollPayslipEntry) => [
    ['CPF', `${formatCPF(data.br.pessoa.cpfTrab)}`],
    [
      'Localidade',
      `${capitalize(payslip.workerData.location.city)} - ${payslip.workerData.location.state}`,
    ],
    ['Competência', `${formatPeriodDate(payslip.period)}`],
    ['Data de emissão', `${formatDateBR(payslip.approvedAt)}`],
    ['Data de pagamento', `${formatDateBR(payslip.date)}`],
  ];

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ReceiptLongOutlined fontSize="inherit" /> &nbsp;
            {getPayslipTypeLabel(payslip.type)}
            {payslip.type === payrollTypes.rpa ? undefined : (
              <>
                <Typography
                  variant="caption"
                  color="strokes.heavy"
                  sx={{ mx: 1 }}
                >
                  •
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontWeight: 700 }}
                >
                  {formatPeriodDate(payslip.period)}
                </Typography>
              </>
            )}
          </Typography>
          <Box py={1}>
            <Typography variant="h1" color="text.primary">
              {payslip.workerData.name}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={2}>
          {!receipt && <Status status={payslip.status}></Status>}
          {actionMenu?.()}
        </Box>
      </Box>

      <DataFetching
        useHook={useFetch}
        Loading={() => (
          <>
            <Typography variant="caption" color="text.secondary">
              <Skeleton width="100%" />
            </Typography>
            <Typography variant="caption" color="text.secondary">
              <Skeleton width="100%" />
            </Typography>
          </>
        )}
        Data={({ data }) => (
          <Box>
            <CltContractInfo payslip={payslip} contract={data} />
            {payslip.type === payrollTypes.rpa ? (
              <Box>
                <Divider sx={{ my: 3 }} />
                {rpaInfoFields(data, payslip).map(([label, value]) => (
                  <Box key={label}>{DoubleColumnListRow(label, value)}</Box>
                ))}
              </Box>
            ) : undefined}
          </Box>
        )}
      />
    </Box>
  );
}

function CltContractInfo({
  payslip,
  contract,
}: {
  payslip: PayrollPayslipEntry;
  contract: ContractEntry | undefined;
}) {
  if (!isBRCltContract(contract)) {
    return null;
  }
  const isAutonomo = isTrabalhadorAutonomo(contract.br.trabalho);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {isAutonomo ? (
        <Box>
          <Typography variant="caption" component="p" color="text.secondary">
            {capitalize(payslip.workerData.jobTitle)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Início em {formatDateBR(payslip.workerData.admissionDate)} |{' '}
            Matrícula {getWorkerId(contract)}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="caption" component="p" color="text.secondary">
            {capitalize(payslip.workerData.jobTitle)} |{' '}
            {contract?.br?.trabalho?.CBOFuncao
              ? `CBO: ${contract?.br?.trabalho?.CBOFuncao} | `
              : ''}
            {formatMoney(payslip.workerData.salary)} / mês
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Admissão em {formatDateBR(payslip.workerData.admissionDate)} |{' '}
            Matrícula {getWorkerId(contract)}
          </Typography>
        </Box>
      )}

      {contract?.br?.pagamento && (
        <Box style={{ textAlign: 'right' }}>
          {contract?.br?.pagamento?.nomeBanco &&
            contract?.br?.pagamento?.agencia &&
            contract?.br?.pagamento?.conta && (
              <>
                <Typography
                  variant="caption"
                  component="p"
                  color="text.secondary"
                >
                  {`Depósito em ${contract?.br?.pagamento?.codigoBanco} | ${contract?.br?.pagamento?.nomeBanco}`}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`Agência: ${contract?.br?.pagamento?.agencia} | Conta: ${contract?.br?.pagamento?.conta}`}
                </Typography>
              </>
            )}
          {!contract?.br?.pagamento?.agencia &&
            contract?.br?.pagamento?.chavePix && (
              <>
                <Typography
                  variant="caption"
                  component="p"
                  color="text.secondary"
                >
                  Envio para chave Pix
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`${contract?.br?.pagamento?.chavePix} | ${parsePixKey(
                    contract?.br?.pagamento?.chavePix,
                  )}`}
                </Typography>
              </>
            )}
        </Box>
      )}
    </Box>
  );
}

function PayslipSummary({ payslip }: { payslip: PayrollPayslipEntry }) {
  const irrfMensal =
    payslip.outputs?.elements['irrf.remuneracaoMensal']?.amount;
  const irrfBase =
    payslip.outputs?.elements['irrf.remuneracaoMensal']?.meta?.explanation
      ?.basis?.total;
  const faixaIRRF =
    irrfMensal && irrfBase ? new Big(irrfMensal).div(new Big(irrfBase)) : 0;
  const tipoFolha = payslip.type;

  if (tipoFolha === payrollTypes.rpa) {
    return <Box display={'flex'} height="40px" width={'100%'} />;
  }

  const elementIdSalarioContribuicao =
    tipoFolha === payrollTypes.thirteenthFirst ||
    tipoFolha === payrollTypes.thirteenthSecond
      ? 'inss.salarioContribuicao13o'
      : 'inss.salarioContribuicaoMensal';

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', px: 1.5, mt: 3, mb: 7 }}
    >
      <Box sx={{ width: '100%', mr: 2.5 }}>
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Salário base
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(payslip.outputs?.workerEarningsTotal?.total)}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Salário de Contribuição p/ INSS
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(
                    payslip.outputs?.elements[elementIdSalarioContribuicao]
                      ?.amount ??
                      // Fallback to old element name
                      payslip.outputs?.elements['inss.salarioContribuicao']
                        ?.amount ??
                      '0',
                  )}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Base FGTS
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(
                    payslip.outputs?.elements?.fgts?.meta?.explanation?.basis
                      ?.total,
                  )}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box width="100%">
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  FGTS do mês
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(payslip.outputs?.elements?.fgts?.amount)}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Base IRRF
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(
                    payslip.outputs?.elements['irrf.remuneracaoMensal']?.meta
                      ?.explanation?.basis?.total,
                  )}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Faixa no IRRF
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatPercentage(faixaIRRF)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

function CompanyFooter({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const useFetchCompany = () => {
    return useGetCompanyEntry({
      pathParams: {
        organizationId,
        companyId,
      },
    });
  };

  return (
    <DataFetching
      useHook={useFetchCompany}
      Loading={() => (
        <>
          <Skeleton width="100%" />
          <Skeleton width="100%" />
        </>
      )}
      Data={({ data }) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.5 }}>
          <Box sx={{ width: '100%' }}>
            <Box display="flex" flexDirection="row">
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  m: 0.5,
                  mr: '12px',
                  border: '1px solid rgba(237, 237, 237, 1)',
                }}
                variant="rounded"
              >
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  {data?.br?.razaoSocial.charAt(0).toUpperCase()}
                </Typography>
              </Avatar>

              <Box display="flex" flexDirection="column">
                <Typography
                  variant="h2"
                  color="text.primary"
                  sx={{
                    textOverflow: 'ellipsis',
                    mt: 0.5,
                  }}
                >
                  {data?.br?.razaoSocial && data?.br?.razaoSocial?.length > 30
                    ? data?.br?.razaoSocial.substring(0, 30) + '...'
                    : data?.br?.razaoSocial}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box width="100%" sx={{ textAlign: 'right' }}>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              {formatCNPJ(data?.br?.cnpj)}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              {data?.br?.endereco?.logradouro}, {data?.br?.endereco?.numero},{' '}
              {data?.br?.endereco?.complemento}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              {data?.br?.endereco?.bairro}, {data?.br?.endereco?.municipio},{' '}
              {data?.br?.endereco?.uf}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              CEP {data?.br?.endereco?.cep}
            </Typography>
          </Box>
        </Box>
      )}
    />
  );
}

function getPayslipTypeLabel(type: PayrollTypes): string {
  switch (type) {
    case 'monthly':
      return 'Holerite mensal';
    case 'advance':
      return 'Recibo de adiantamento';
    case 'thirteenthFirst':
      return 'Recibo de 13º salário - 1ª parcela';
    case 'thirteenthSecond':
      return 'Recibo de 13º salário - 2ª parcela';
    case 'rpa':
      return 'Recibo de Pagamento Autônomo';
    default:
      return 'Recibo de pagamento complementar';
  }
}

function Status({ status }: { status: string }) {
  const statusMap: Record<string, Record<string, string>> = {
    approved: {
      label: 'Não enviado',
      color: '#93500B',
    },
    scheduled: {
      label: 'Agendado',
      color: '#93500B',
    },
    sent: {
      label: 'Enviado',
      color: '#0058DB',
    },
    archived: {
      label: 'Arquivado',
      color: '#616161',
    },
  };

  const { label, color } = statusMap[status] || statusMap['archived'];
  return (
    <Typography variant="body2" color={color}>
      <Typography fontSize="150%" display="inline" color={color}>
        •{'\t  '}
      </Typography>
      {label}
    </Typography>
  );
}

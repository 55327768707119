import { TemplateCategoryEnum } from "@octopus/api";

export type TemplateCategoryLabels = {
  [key in TemplateCategoryEnum]: string;
};

export const templateCategoryLabels: TemplateCategoryLabels = {
  admission: 'Admissão',
  vacations: 'Férias',
  termination: 'Rescisão',
};

import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import {
  ContractBRCltDependent,
  ContractBRPjEntryDependente,
  ContractDocumentEntry,
  useUploadContractDocument,
} from '@octopus/api';
import { translate } from '@octopus/i18n';

import {
  getContentType,
  getErrorSnackbar,
  getSuccessSnackbar,
} from '../../../../app/people/person/documents/utils';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { FileComponent } from '../../file';

type DependenteDocumentsProps = {
  dependente: ContractBRCltDependent | ContractBRPjEntryDependente | undefined;
  organizationId: string;
  contractId: string;
  dependentDocuments: ContractDocumentEntry[] | undefined;
  editing: boolean;
};

export function DependenteDocuments(props: DependenteDocumentsProps) {
  const {
    dependente,
    organizationId,
    contractId,
    dependentDocuments,
    editing,
  } = props;
  const { showSnackbar } = useSnackbar();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const dependentFile = dependentDocuments?.[0];
    const f = dependentFile
      ? new File(
          [dependentFile.downloadUrl],
          dependentFile.fileName ?? dependentFile.type,
          {
            type: dependentFile.contentType,
          },
        )
      : null;

    setFile(f);
  }, [dependentDocuments]);

  const uploadDocumentMutation = useUploadContractDocument({
    onSuccess: async (response) => {
      if (file && response.uploadUrl) {
        try {
          await fetch(response.uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type,
            },
          });
        } catch (error) {
          console.error('Erro ao fazer upload do arquivo:', error);
        }
      }
    },
    onError: (error: { status: number | 'unknown'; payload: string }) => {
      console.error('Erro ao criar documento:', error);
    },
  });

  if (!dependente || !dependente.id) {
    return null;
  }

  const handleFileChange = async (file: File) => {
    setFile(file);

    if (!file) {
      return;
    }

    const dependentId = dependente.id;
    setIsLoading(true);

    try {
      await uploadDocumentMutation.mutateAsync({
        pathParams: {
          organizationId,
          contractId,
        },
        body: {
          type: 'outro',
          contentType: getContentType(file),
          contentLength: file.size,
          dependentId:
            dependentId && dependentId !== 'colaborador' ? dependentId : null,
          fileName: file.name,
        },
      });

      showSnackbar(getSuccessSnackbar('Documento adicionado'));
    } catch (e) {
      console.error(e);
      showSnackbar(
        getErrorSnackbar(
          e.message?.startsWith('CTR')
            ? translate(e.message, 'pt')
            : 'Ocorreu um erro ao adicionar o documento',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <FileComponent
        description="São aceitos arquivos com tamanho máximo de 10MB."
        disabled={isLoading || editing}
        disabledMessage={
          isLoading
            ? 'Por favor aguarde...'
            : editing
              ? 'Salve o dependente antes de enviar o documento'
              : 'Nenhum documento pode ser enviado nesse momento'
        }
        setFile={handleFileChange}
        showDeleteButton={false}
        isLoading={isLoading}
        file={file}
        filePreviewType="list"
        defaultExpanded={false}
      />
    </Box>
  );
}

import { ChangeEvent, useState } from 'react';

import { IconDownload, IconFolderCog, IconTrash } from '@tabler/icons-react';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';

import { TemplateCategoryEnum, fetchDeleteTemplate, useGetAllTemplates } from '@octopus/api';
import { DataGrid, DataGridSkeleton, GridColDef, GridValueGetterParams } from '@octopus/ui/data-grid';

import { PageContainer } from '../../../modules/components/page/PageContainer';
import { PageTitle } from '../../../modules/components/page/PageTitle';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import { templateCategoryLabels } from './types';

type Props = {
  organizationId: string;
};

export function ManageTemplates({ organizationId }: Props) {
  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const { data: templates, isLoading: isLoadingTemplates } = useGetAllTemplates({ pathParams: { organizationId } });

  const handleDownloadTemplate = (templateId: string) => {
    // todo: call api to generate document
    setCurrentTemplateId(templateId);
    setIsDownloading(true);
    console.log('Baixar documento');
    setIsDownloading(false);
    setCurrentTemplateId(null);
  };

  const handleDeleteTemplate = (templateId: string) => {
    // todo: call api to delete template
    setCurrentTemplateId(templateId);
    setIsDeleting(true);

    fetchDeleteTemplate({ pathParams: { organizationId, templateId } })
      .then(() => {
        showSnackbar({
          isOpen: true,
          Message: 'Template excluído com sucesso',
        });
      }).catch((error) => {
        console.error(error);
        showSnackbar({
          isOpen: true,
          Message: 'Erro ao excluir template',
          variant: 'error',
        });
      }).finally(() => {
        setIsDeleting(false);
        setCurrentTemplateId(null);
      });
  };

  const disableActions = isDownloading || isDeleting || isLoadingTemplates;

  const columns: GridColDef[] = [
    {
      field: 'name', headerName: 'Nome', flex: 1, valueGetter: (params: GridValueGetterParams) => {
        return params.row.name
      },
    },
    {
      field: 'category', headerName: 'Categoria', flex: 1, valueGetter: (params: GridValueGetterParams) => {
        return templateCategoryLabels[params.row.category as TemplateCategoryEnum]
      },
    },
    {
      field: 'download', headerName: 'Baixar template', valueGetter: (params: GridValueGetterParams) => {
        return params.row.templateId
      },
      renderCell: ({ value }) => {
        const templateId = value;

        return <LoadingButton
          loading={isDownloading && currentTemplateId === templateId}
          onClick={() => handleDownloadTemplate(templateId)}
          color="primary"
          variant='text' sx={{ mx: '6px' }}
        >
          <IconDownload width={20}
            height={20}
            className={disableActions ? 'disabled' : 'main'} />
        </LoadingButton>
      },
    },
    {
      field: 'delete', headerName: 'Excluir template', valueGetter: (params: GridValueGetterParams) => {
        return params.row.templateId
      },
      renderCell: ({ value }) => {
        const templateId = value;
        return <LoadingButton
          loading={isDeleting && currentTemplateId === templateId}
          onClick={() => handleDeleteTemplate(templateId)}
          color="primary"
          variant='text'
          sx={{ mx: '6px' }}
        >
          <IconTrash
            width={20}
            height={20}
            className={disableActions ? 'disabled' : 'error'}
          />
        </LoadingButton>;
      },
    },
  ];

  return (
    <Box display="flex" width="100%">
      <PageContainer>
        <PageTitle title="Gerenciar templates" icon={IconFolderCog} />
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <DataGrid
            rows={isLoadingTemplates ? [] : templates || []}
            columns={columns}
            emptyMessage={isLoadingTemplates ? <DataGridSkeleton columnsCount={columns.length} rowsCount={3} /> : 'Nenhum template encontrado'}
            totalRowCount={templates?.length}
            getRowId={(row) => row.templateId}
            sortingProps={{
              field: 'name',
              order: 'asc',
              setField: (field?: string) => { },
              setOrder: (order?: 'asc' | 'desc' | undefined) => { },
            }}
            paginationProps={{
              page: 1,
              rowsPerPage: 10,
              rowsPerPageOptions: [10, 20, 30],
              handleChangePage: (_event, _newPage: number) => { },
              handleChangeRowsPerPage: (_event: ChangeEvent<HTMLInputElement>) => { },
            }}
          />
        </Box>
      </PageContainer>
    </Box>
  );
}

import { useState } from 'react';

import { IconFileExport } from '@tabler/icons-react';

import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { ContractEntry, TemplateEntry } from '@octopus/api';

import { ContractSelector } from '../../../modules/components/contracts/ContractSelector';
import { PageContainer } from '../../../modules/components/page/PageContainer';
import { PageTitle } from '../../../modules/components/page/PageTitle';

export type Props = {
  organizationId: string;
};

const _tempTemplates: TemplateEntry[] = [
  {
    templateId: '1a',
    name: 'Contrato Estágio',
    category: 'admission',
    organizationId: '1',
    active: true,
    createdOn: new Date().toISOString(),
    createdBy: 'system',
    version: 1,
  },
  {
    templateId: '2a',
    name: 'Resumo benefícios',
    category: 'admission',
    organizationId: '1',
    active: true,
    createdOn: new Date().toISOString(),
    createdBy: 'system',
    version: 1,
  },
  {
    templateId: '3a',
    name: 'Resumo de férias',
    category: 'vacations',
    organizationId: '1',
    active: true,
    createdOn: new Date().toISOString(),
    createdBy: 'system',
    version: 1,
  },
  {
    templateId: '4a',
    name: 'Informe de recisão',
    category: 'termination',
    organizationId: '1',
    active: true,
    createdOn: new Date().toISOString(),
    createdBy: 'system',
    version: 1,
  },
];

export function GenerateDocument({ organizationId }: Props) {
  const [selectedContract, setSelectedContract] =
    useState<ContractEntry | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEntry | null>(
    null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleDownloadDocument = () => {
    // todo: call api to generate document
    console.log('Baixar documento', selectedContract);
  };

  return (
    <Box display="flex" width="100%">
      <PageContainer>
        <PageTitle title="Gerar documento" icon={IconFileExport} />
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="body1">
            Selecione o template desejado e o contrato a ser utilizado para
            preencher o template:{' '}
          </Typography>
          <SelectTemplate
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
          <SelectContract
            organizationId={organizationId}
            selectedContract={selectedContract}
            setSelectedContract={setSelectedContract}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <Button
            disabled={!selectedContract || !selectedTemplate}
            onClick={handleDownloadDocument}
            color="primary"
            sx={{ mx: '6px' }}
          >
            Baixar documento
          </Button>
        </Box>
      </PageContainer>
    </Box>
  );
}

const SelectContract = ({
  organizationId,
  selectedContract,
  setSelectedContract,
  errorMessage,
}: {
  organizationId: string;
  selectedContract: ContractEntry | null;
  setSelectedContract: (contract: ContractEntry) => void;
  errorMessage: string | null;
  setErrorMessage: (errorMessage: string) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <ContractSelector
        organizationId={organizationId}
        activeContract={selectedContract}
        setActiveContract={setSelectedContract}
        searchFilters={
          {
            // elements: {
            //   contractId: [{ not: contractId }],
            //   status: [{ not: contractStatuses.terminated }],
            // },
          }
        }
        searchBarProps={{
          withStartAdornment: false,
          placeholder: 'Selecione uma pessoa',
        }}
        error={!!errorMessage}
      />
      {errorMessage && (
        <Box pl={1}>
          <Typography variant="caption" color="red">
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const SelectTemplate = ({
  selectedTemplate,
  setSelectedTemplate,
}: {
    selectedTemplate: TemplateEntry | null;
    setSelectedTemplate: (template: TemplateEntry) => void;
}) => {
  const [option, setOption] = useState<TemplateEntry | null>(selectedTemplate);

  const getOptionLabel = (option: TemplateEntry) => {
    return !option ? '' : `[${option.category}] ${option.name}`;
  };

  const options = _tempTemplates.sort((a, b) => {
    return a.category.localeCompare(b.category);
  });

  return (
    <Autocomplete
      sx={{ px: '6px' }}
      forcePopupIcon
      disablePortal
      fullWidth
      clearOnBlur={false}
      noOptionsText="Nenhuma opção encontrada."
      value={option}
      onChange={(_, newValue) => {
        setOption(newValue);
        setSelectedTemplate(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          fullWidth
          placeholder="Selecione um template"
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              autoComplete: 'off',
              defaultValue: null,
              value: getOptionLabel(option),
            },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option?.templateId === value?.templateId}
      getOptionKey={(option) => (!option ? '' : option.templateId)}
      getOptionLabel={getOptionLabel}
    />
  );
};
